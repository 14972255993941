import React, { useState } from "react";
import { useLocation, Link } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Images } from "../../../assets/assets";
import "./Header.css";
import Lottie from 'react-lottie-player'
import greenCall from "../../../assets/json-files/call-green.json";
import { Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
const Header = () => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const location = useLocation();

    return (
        <div>
            <header className="site-header">

                <Container className="custom-container">
                    <Navbar expand="lg">
                        <div className="d-flex justify-content-between align-items-center w-100">
                            {location.pathname == "/roanmoving" ? <img
                                src={Images.quantumMaxLogo}
                                className="img-fluid site-main-logo"
                                alt="img"
                            /> :
                                <Link to="/">
                                    <img
                                        src={Images.quantumMaxLogo}
                                        className="img-fluid site-main-logo"
                                        alt="img"
                                    />
                                </Link>
                            }
                            {/* desktop nav */}
                            <div id="basic-navbar-nav" className="pages-names desktop-nav d-flex align-items-center flex-fill justify-content-end">
                                <Nav className="ms-auto d-flex align-items-center">
                                    <div className="haeder-phone d-flex align-items-center">
                                        <a
                                            className="d-flex align-items-center ms-xl-3 ms-lg-2 ms-1 gray-clr"
                                            href={location.pathname === "/internet" ? "tel:+18662609968" : "tel:+18662612148"}
                                        >
                                            <Lottie
                                                className="call-lottie"
                                                loop
                                                animationData={greenCall}
                                                play
                                            />
                                            <span className="mobile-nbr">
                                            {location.pathname === "/internet" ? "866 260 9968" : "866-261-2148"}
                                            </span>
                                        </a>
                                    </div>
                                </Nav>
                            </div>

                            <div className="mobile-menu-bar">
                                <div className="haeder-phone d-flex align-items-center">
                                    <a
                                        className="d-flex align-items-center ms-xl-3 ms-lg-2 ms-1 gray-clr"
                                        href="tel:+18662612148"
                                    >
                                        <Lottie
                                            className="call-lottie"
                                            loop
                                            animationData={greenCall}
                                            play
                                        />
                                        <span className="mobile-nbr">
                                            866-261-2148
                                        </span>
                                    </a>
                                </div>
                                {/* end desktop nav*/}

                                {/* mobile navbar */}
                                {location.pathname == "/roanmoving" ? null : <Button className="menu-burger-btn" onClick={handleShow}>
                                    <span className="meun-burger"><FontAwesomeIcon icon={faBars} /></span>
                                </Button>
                                }

                                {location.pathname == "/roanmoving" ? null : <Offcanvas show={show} onHide={handleClose} className="side-bar-menu">
                                    <Offcanvas.Header closeButton></Offcanvas.Header>
                                    <Offcanvas.Body>
                                        <div id="basic-navbar-nav" className="pages-names mobile-nav">
                                            <Nav>
                                                <Link to="/" className={location.pathname === "/" ? "active" : ""}>Home</Link>
                                                <Link to="cabletv" className={location.pathname === "/cabletv" ? "active" : ""}>Cable TV</Link>
                                                <Link to="internet" className={location.pathname === "/internet" ? "active" : ""}>Internet</Link>
                                                <Link to="phone" className={location.pathname === "/phone" ? "active" : ""}>Phone</Link>
                                                {/* <Link to="business" className={location.pathname === "/business" ? "active" : ""}>Business</Link> */}
                                                <Link to="https://comlinktv.com/bundles" target="_blank">Comlink TV</Link>
                                                <Link to="https://www.saleslinkcrm.com/acp/comlinkbundle.com" target="_blank">Free Internet</Link>
                                                {/* <Link to="optimum">Find Best Deals</Link> */}
                                                <Link to="getaqoute" className={location.pathname === "/getaqoute" ? "active" : ""}>Contact Us</Link>
                                            </Nav>
                                        </div>
                                    </Offcanvas.Body>
                                </Offcanvas>}
                            </div>
                        </div>
                    </Navbar>
                </Container>
            </header>
        </div>
    );
};

export default Header;
