import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Container, Row, Col } from "react-bootstrap";
import { Images } from "../../../assets/assets";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF } from "@fortawesome/free-brands-svg-icons";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import { faYoutube } from "@fortawesome/free-brands-svg-icons";
import { faPinterest } from "@fortawesome/free-brands-svg-icons";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import "./Footer.css";
import { Link, useLocation } from "react-router-dom";

const API_URL = process.env.REACT_APP_API_URL;
const API_KEY = process.env.REACT_APP_API_KEY;
const ORIGIN = process.env.REACT_APP_ORIGIN;

const headers = {
    "Content-Type": "application/json",
    origin: ORIGIN, // Replace with the actual client URL
    apikey: API_KEY, // Replace with your actual API key
};

const intialValue = {
    email: "",
};

const validationSchema = Yup.object().shape({
    email: Yup.string()
        .email("Invalid email address")
        .required("Please enter your email")
        .nullable(),
});

const apiUrl = `${API_URL}/leads/create`;

const Footer = () => {
    const location = useLocation();

    const formik = useFormik({
        initialValues: intialValue,
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            toast.dismiss();
            toast.success("Thank you for subscribing to our newsletter! We're so grateful to have you as a part of our community.");
            formik.resetForm();
        },
    });

    return (
        <div>
            <footer className="site-footer">
                <Container fluid className="custom-container">
                    <div className="top-footer">
                        <Row className="get-started">
                            <div className="col-lg-8">
                                <div className="get-start-content">
                                    <h5>Get Started with Comlink</h5>
                                    <p>Comlinkbundle can help you find the best-bundled deal for your TV, Internet, and Phone services to save your time and money.</p>
                                </div>
                            </div>
                            {location.pathname == "/roanmoving" ? null :
                                <div className="col-lg-4">
                                    <div className="d-flex justify-content-lg-end justify-content-center mt-lg-0 mt-3">
                                        {location.pathname == "/getaqoute" ? <Link to="/" className="btn theme-btn">Order Now</Link> :
                                            <Link to="/getaqoute" className="btn theme-btn">Contact Us</Link>
                                        }
                                    </div>
                                </div>}
                        </Row>
                        <Row className="footer-content-row">
                            <Col lg={3}>
                                <div className="footer-logo">
                                    <img src={Images.footerLogo} className="img-fluid" alt="img" />
                                </div>
                                <div className="footer-content">
                                    Find top internet, TV & phone deals with Comlink Bundle. Save time & money with our expert guidance. Bundle your services now!
                                </div>
                            </Col>
                            <Col lg={2}>
                                {location.pathname == "/roanmoving" ? null :
                                    <div className="footer-links ps-xl-5">
                                        <h5>Menu</h5>
                                        <ul className="list-unstyled">
                                            <li>
                                                <a href="/">Home</a>
                                            </li>
                                            <li>
                                                <a href="/cabletv">Cable Tv</a>
                                            </li>
                                            <li>
                                                <a href="/internet">Internet </a>
                                            </li>
                                            <li>
                                                <a href="/phone">Phone</a>
                                            </li>
                                            <li>
                                                <Link to="https://comlinktv.com/bundles" target="_blank">Comlink TV</Link>
                                            </li>
                                            <li>
                                                <Link to="https://www.saleslinkcrm.com/acp/comlinkbundle.com" target="_blank">Free Internet</Link>
                                            </li>
                                        </ul>
                                    </div>}
                            </Col>
                            <Col lg={2}>
                                {location.pathname == "/roanmoving" ? null :
                                    <div className="footer-links">
                                        <h5 className="">Quick Links</h5>
                                        <ul className="list-unstyled">
                                            {/* <li>
                                            <a href="#">Blog</a>
                                        </li> */}
                                            <li>
                                                <Link to="/privacy-policy">Privacy Policy</Link>
                                            </li>
                                            {/* <li>
                                            <a href="#">Terms & Conditions</a>
                                        </li> */}
                                        </ul>
                                    </div>}
                            </Col>
                            <Col lg={2}>
                                <div className="footer-links">
                                    <h5 className="">Contact Us</h5>
                                    <div className="contact-list">
                                        <div className="footer-links">
                                            <ul className="list-unstyled contact-info flex-column">
                                                <li>
                                                    <a href={location.pathname === "/internet" ? "tel:+18662609968" : "tel:+18662612148"}>
                                                        <span className="me-2"><FontAwesomeIcon icon={faPhone} /></span>
                                                        <span>{location.pathname === "/internet" ? "866 260 9968" : "866-261-2148"}</span>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="mailto:support@gocomlink.com">
                                                        <span className="me-2">
                                                            <FontAwesomeIcon icon={faEnvelope} />
                                                        </span>
                                                        <span className="opacity-1">support@gocomlink.com</span>
                                                    </a>
                                                </li>
                                                <li>
                                                    {/* <a href="#"> */}
                                                    <span className="me-2">
                                                        <FontAwesomeIcon icon={faLocationDot} />
                                                    </span>
                                                    <span>
                                                        2891 Center Point Fort Myers, Florida 33916
                                                    </span>
                                                    {/* </a> */}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={3}>
                                <form onSubmit={formik.handleSubmit}>
                                    <div className="footer-newsletter">
                                        <h5>Newsletter</h5>
                                        <div className="field-wrapper">
                                            <input
                                                className={`form-control ${formik.touched.email && formik.errors.email ? "is-invalid" : ""
                                                    }`}
                                                name="email"
                                                type="email"
                                                placeholder="Enter your email"
                                                {...formik.getFieldProps("email")}
                                            />
                                            <button type="submit" className="btn green-btn">
                                                <img src={Images.Link} className="img-fluid" alt="img" />
                                            </button>
                                        </div>
                                        <div className="follow-us">
                                            <ul className="list-unstyled">
                                                <li>
                                                    <a href="https://www.facebook.com/comlinklinked/" className="facebook">
                                                        <span><FontAwesomeIcon icon={faFacebookF} /></span>
                                                    </a>
                                                </li>
                                                {/* <li>
                                                <a href="#" className="linkedin">
                                                    <span><FontAwesomeIcon icon={faLinkedinIn} /></span>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#" className="twitter">
                                                    <span><FontAwesomeIcon icon={faTwitter} /></span>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#" className="pinterest">
                                                    <span><FontAwesomeIcon icon={faPinterest} /></span>
                                                </a>
                                            </li> */}
                                                <li>
                                                    <a href="https://www.youtube.com/@comlinktv" className="youtube">
                                                        <span><FontAwesomeIcon icon={faYoutube} /></span>

                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </form>
                            </Col>
                        </Row>
                    </div>
                    <div className="bottom-footer">
                        <div className="copyrights">
                            <p className="p-text-20">
                                Copyright Reserved by Comlink Bundle
                            </p>
                        </div>
                    </div>
                </Container>
            </footer>
        </div>
    );
};
export default Footer;

